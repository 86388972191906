import React from "react";

export default function AlertBanner() {
  const targetDate = new Date("2024-11-25");
  const today = new Date();

  if (targetDate < today) {
    return null;
  }
  return (
    <>
      <div className='alert alert-danger mb-0 px-5'>
        <span className='mr-1' style={{ fontWeight: "bold" }}>
          ATTENTION:
        </span>
        DASH will be unavailable on{" "}
        <span className='mr-1' style={{ fontWeight: "bold" }}>
          Sunday, November 24, 2024
        </span>{" "}
        to undergo scheduled maintenance. During this time, you may experience
        limited or no access to DASH. We appreciate your patience and
        understanding as we perform important updates to improve our platform.
      </div>
    </>
  );
}
